Kubrick.Slider = {
  // Status tracker
  STATUS: Kubrick.STATUS.READY,

  // Classes
  CLASS_ANIMATING: "animating",

  // Vars
  _duration: 1500,

  // Selectors
  _block_selector: ".block.casestudies, .block.featuredinsights",
  _slide_selector: ".casestudyslide, .insightthumb",
  _position_selector: ".control-counter .location",
  _next_selector: ".control-buttons .next",
  _prev_selector: ".control-buttons .previous",

  // Objects
  _controllers: [],
  _configs: [],

  // Init
  start: function(config) {
    Kubrick._log("Slider: Starting...", config);
    this._setupBlock(document.querySelector("#content ." + config.id));
    this._configs.push(config);
  },

  // Setup block
  _setupBlock: function($BLOCK) {
    var controller = {
      $BLOCK: $BLOCK,
      $SLIDES: $BLOCK.querySelectorAll(this._slide_selector),
      $NEXT: $BLOCK.querySelector(this._next_selector),
      $PREVIOUS: $BLOCK.querySelector(this._prev_selector),
      $POSITION: $BLOCK.querySelector(this._position_selector),
      STATUS: Kubrick.STATUS.READY,
      POSITION: 1
    };
    controller.$NEXT.addEventListener(
      "click",
      this._nextSlide.bind(controller)
    );
    controller.$PREVIOUS.addEventListener(
      "click",
      this._previousSlide.bind(controller)
    );

    // Collate
    this._controllers.push(controller);
  },

  _nextSlide: function(e) {
    if (
      this.STATUS == Kubrick.STATUS.READY &&
      this.POSITION < this.$SLIDES.length
    ) {
      // Update status to show it's running
      this.STATUS = Kubrick.STATUS.RUNNING;
      this.$BLOCK.classList.add(Kubrick.Slider.CLASS_ANIMATING);

      // Update the position text
      Kubrick.Slider._updatePosition(this.$POSITION, ++this.POSITION);

      anime({
        targets: this.$SLIDES,
        translateX: "-=100%",
        easing: Kubrick.EASING.NORMAL,
        duration: Kubrick.Slider._duration,
        complete: function() {
          this.STATUS = Kubrick.STATUS.READY;
          this.$BLOCK.classList.remove(Kubrick.Slider.CLASS_ANIMATING);
        }.bind(this)
      });
    }
  },

  _previousSlide: function(e) {
    if (this.STATUS == Kubrick.STATUS.READY && this.POSITION > 1) {
      // Update status to show it's running
      this.STATUS = Kubrick.STATUS.RUNNING;

      // Update the position text
      Kubrick.Slider._updatePosition(this.$POSITION, --this.POSITION);

      anime({
        targets: this.$SLIDES,
        translateX: "+=100%",
        easing: Kubrick.EASING.NORMAL,
        duration: Kubrick.Slider._duration,
        complete: function() {
          this.STATUS = Kubrick.STATUS.READY;
        }.bind(this)
      });
    }
  },

  _updatePosition: function(positionDOM, positionValue) {
    positionDOM.textContent = positionValue;
  },

  getAllControllers: function() {
    return this._controllers;
  }
};
